import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

export const styles: { [key: string]: SxProps<Theme> } = {
  Loan: {
    mb: 0,
    fontWeight: "600",
    color: "red",
  },
  indicator: {
    fontWeight: "600",
    display: "inline-block",
    padding: "0 10px",
  },
  indicatorCircle: {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    marginRight: "2px",
    display: "inline-block",
  },
  status: {
    borderTopLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    fontSize: "10px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    padding: "3px 0px",
  },
  headRow: {
    backgroundColor: "#FBE2E0",
    textAlign: "center",
    fontSize: "11px",
    fontWeight: "bold",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
  },
  cell: {
    textAlign: "center",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    fontSize: "11px",
    fontWeight: "420",
  },
  pagination: {
    fontSize: "12px",
    fontWeight: "500",
    borderBlockWidth: "0.5px",
    borderRadius: "5px",
    textAlign: "center",
    margin: "1px",
    marginTop: "6px",
    height: "20px",
    width: "22px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  ChangeButton: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "black",
  },
};
