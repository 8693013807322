export const routes = {
    root: '/',
    login: '/login',
    forgot_password: '/forgot-password',
    loans: '/loans',
    loan: (id?: string) => `/loans/${id}`,
    users: '/users',
    user: (id?: string) => `/users/${id}`,
    roles: '/roles',
    role: (id?: string) => `/roles/${id}`
}
