import { FormModel } from "../../../../components/Form";
import { Validators } from "../../../../utils/validators";

export const TransactionDetailsForm = (emiPending: number): FormModel[] => {
  return [
    {
      label: "Loan ID",
      value: "",
      size: "small",
      autoFocus: false,
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "orderId",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      disabled: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
        "& .MuiInputBase-root": {
          border: "1px solid #000000",
          borderTopLeftRadius: "30px",
          borderBottomRightRadius: "30px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          paddingLeft: "40px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Transaction Id",
      value: "",
      size: "small",
      autoFocus: true,
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "transactionId",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      disabled: emiPending === 0,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
        "& .MuiInputBase-root": {
          border: "1px solid #000000",
          borderTopLeftRadius: "30px",
          borderBottomRightRadius: "30px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          paddingLeft: "40px",
          paddingRight: "40px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Loan Amount",
      value: "",
      size: "small",
      autoFocus: false,
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "loanAmount",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
        { check: Validators.amount, message: "Please enter a valid Amount" },
      ],
      responsive: { xs: 12 },
      required: true,
      disabled: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
        "& .MuiInputBase-root": {
          border: "1px solid #000000",
          borderTopLeftRadius: "30px",
          borderBottomRightRadius: "30px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          paddingLeft: "40px",
          paddingRight: "40px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "EMI Amount Paid",
      value: "",
      size: "small",
      autoFocus: false,
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "paidEmi",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
        { check: Validators.amount, message: "Please enter a valid Amount" },
        {
          check: Validators.max,
          num: emiPending,
          message: "EMI Paid cannot be greater than Pending Amount",
        },
      ],
      responsive: { xs: 12 },
      required: true,
      disabled: emiPending === 0,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
        "& .MuiInputBase-root": {
          border: "1px solid #000000",
          borderTopLeftRadius: "30px",
          borderBottomRightRadius: "30px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          paddingLeft: "40px",
          paddingRight: "40px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Transaction Date",
      value: "",
      size: "small",
      autoFocus: false,
      type: "date",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "transactionDate",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      disabled: emiPending === 0,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
        "& .MuiInputBase-root": {
          border: "1px solid #000000",
          borderTopLeftRadius: "30px",
          borderBottomRightRadius: "30px",
          paddingLeft: "25px",
          paddingRight: "25px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      disableFuture: true,
      hiddenLabel: true,
    },
    {
      label: "Payment Method",
      value: "",
      size: "small",
      autoFocus: false,
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "paymentMethod",
      options: [{ label: "Cash", value: "Cash" }],
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      disabled: emiPending === 0,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
        border: "1px solid #000000",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          paddingLeft: "40px",
          paddingRight: "40px",
        },
      },
      hiddenLabel: true,
    },
  ];
};
