import { routes } from "./routes";

export const drawerWidth: number = 220;

export const GOOGLE_API_KEY: string = "AIzaSyDqsfB7FsBp-Kkw4ause-SmyTtt3VKOPo0";

export const menu = [
  {
    icon: "money",
    title: "All Loans",
    link: routes.loans,
  },
  {
    icon: "users",
    title: "All Users",
    link: routes.users,
  },
  {
    icon: "roles",
    title: "Roles",
    link: routes.roles,
  },
];

export const loan_status: {
  label: string;
  value: string;
  icon?: string;
  disabled?: boolean;
}[] = [
  {
    label: "Open",
    value: "OPEN",
    disabled: true,
  },
  {
    label: "Sanctioned",
    value: "SANCTIONED",
  },
  {
    label: "Disbursed",
    value: "DISBURSED",
  },
  {
    label: "Closed",
    value: "CLOSED",
  },
  {
    label: "Rejected",
    value: "REJECTED",
  },
];

export const loan_tenure: {
  label: string;
  value: string;
  icon?: string;
  disabled?: boolean;
}[] = [
  {
    label: "3 Months",
    value: "3",
  },
  {
    label: "6 Months",
    value: "6",
  },
];

export const interest_type: {
  label: string;
  value: string;
  icon?: string;
  disabled?: boolean;
}[] = [
  {
    label: "Simple Interest",
    value: "SIMPLE_INTEREST",
  },
  {
    label: "Compound Interest",
    value: "COMPOUND_INTEREST",
  },
];
