import React, {FC, useState} from "react";
import {useSelector} from "react-redux";
import {Authenticated} from "../../utils/redux";
import {Navigate} from "react-router-dom";
import {routes} from "../../utils/constants/routes";
import {
    AppBar,
    Box,
    CssBaseline,
    Drawer,
    Toolbar
} from "@mui/material";
import {drawerWidth} from "../../utils/constants/constants";
import Sidebar from './Drawer';
import TopBar from "./TopBar";

const PrivateRoute: FC<{ Component: FC; window?: () => Window; }> = ({Component, window}) => {
    const isAuthenticated = useSelector(Authenticated);
    const [mobileOpen, setMobileOpen] = useState(false);

    const container = window !== undefined ? () => window().document.body : undefined;

    const handleDrawerToggle = () => {
        setMobileOpen(prevState => !prevState);
    };

    return isAuthenticated ? (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                sx={{
                    width: {sm: `calc(100% - ${drawerWidth}px)`},
                    ml: {sm: `${drawerWidth}px`},
                    backgroundColor: '#FBE2E0',
                    boxShadow: 'none'
                }}
            >
                <Toolbar>
                    <TopBar handleDrawerToggle={handleDrawerToggle}/>
                </Toolbar>
            </AppBar>
            <Box component="nav" sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}>
                <Drawer container={container} variant="temporary" open={mobileOpen} onClose={handleDrawerToggle}
                        ModalProps={{keepMounted: true}} sx={{
                    display: {xs: 'block', sm: 'none'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth}
                }}>
                    <Sidebar/>
                </Drawer>
                <Drawer variant="permanent" sx={{
                    display: {xs: 'none', sm: 'block'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth, borderRight: 'none'}
                }} open>
                    <Sidebar/>
                </Drawer>
            </Box>
            <Box component="main" sx={{flexGrow: 1, p: 3, width: {sm: `calc(100% - ${drawerWidth}px)`}}}>
                <Toolbar/>
                <Component/>
            </Box>
        </Box>
    ) : <Navigate to={routes.login}/>;
};

export default PrivateRoute;
