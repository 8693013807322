import React, { FC, useCallback, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import axiosInstance from "../../../../utils/axios";
import { URLS } from "../../../../utils/constants/urls";
import { styles } from "../styles";
import { FormModel } from "../../../../components/Form";
import RenderFormFields from "../../../../components/Form/helper";
import { BusinessDetailsForm } from "./BusinessDetailsForm";
import { RefreshToken } from "../../../../utils/refresh-token";
import { clearStorage } from "../../../../utils/storage";
import { logout } from "../../../../utils/redux";
import { useDispatch } from "react-redux";

interface BusinessDetails {
  loanReferenceNo: string;
  firmName: string;
  firmType: string;
  businessPanCard: string;
  gstNo: number | null;
  businessRegistrationNo: string;
  natureOfBusinessActivity: string;
  purposeOfLoan: number | null;
}

const BusinessDetail: FC<{ id: string | undefined }> = ({ id }) => {
  const dispatch = useDispatch();
  const [businessDetail, setBusinessDetails] = useState<BusinessDetails>();

  const getAllDetails = useCallback(async () => {
    try {
      const {
        status,
        data,
      }: {
        status: number;
        data: BusinessDetails;
      } = await axiosInstance.get(URLS.loan(id, "business-details"));

      if (status === 200) {
        setBusinessDetails(data);
      } else {
        console.error("Unexpected status:", status);
      }
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          // API - Generate Access Token from Refresh Token
          const { status } = await RefreshToken();
          if (status) {
            await getAllDetails();
          } else {
            clearStorage("all");
            dispatch(logout());
          }
        }
      }
      console.log(e);
    }
  }, [id, dispatch]);

  useEffect(() => {
    (async () => {
      await getAllDetails();
    })();
  }, [getAllDetails]);

  return (
    <Grid
      container
      style={{
        backgroundColor: "#fdf5f4",
        padding: "40px",
        borderTopLeftRadius: "10px",
        borderBottomRightRadius: "10px",
        width: "100%",
        marginLeft: 0,
      }}
      spacing={2}
    >
      {BusinessDetailsForm.map((item: FormModel) => ({
        ...item,
        value: !!businessDetail
          ? businessDetail[item.field as keyof BusinessDetails] || ""
          : "",
      })).map((item: FormModel, index: number) => (
        <>
          {index < 6 ? (
            <Grid
              key={index}
              item
              xs={12}
              lg={6}
              marginBottom={3}
              display={"flex"}
              flexDirection={"row"}
            >
              <Typography
                fontSize={"11px"}
                sx={{
                  ...styles.detail,
                  width: "200px",
                  textDecorationColor: "red",
                }}
              >
                {item.label}
              </Typography>
              <RenderFormFields
                value={item.value as string}
                item={item}
                handleChange={() => {}}
              />
            </Grid>
          ) : (
            <>
              <Grid key={index} item xs={12} marginBottom={3}>
                <Typography
                  sx={{
                    ...styles.detail,
                    width: "200px",
                    textDecorationColor: "red",
                    marginBottom: "10px",
                  }}
                >
                  {item.label}
                </Typography>
                <RenderFormFields
                  value={item.value as string}
                  item={item}
                  handleChange={() => {}}
                />
              </Grid>
            </>
          )}
        </>
      ))}
    </Grid>
  );
};

export default BusinessDetail;
