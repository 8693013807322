import React, { FC, useCallback, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { styles } from "../styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { SxProps, Theme } from "@mui/system";
import axiosInstance from "../../../../utils/axios";
import { URLS } from "../../../../utils/constants/urls";
import { TransactionDetailsForm } from "./TransactionDetailsForm";
import { FormModel } from "../../../../components/Form";
import RenderFormFields from "../../../../components/Form/helper";
import { RefreshToken } from "../../../../utils/refresh-token";
import { clearStorage } from "../../../../utils/storage";
import { logout, setNotificationMessage } from "../../../../utils/redux";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import PrimaryButton from "../../../../components/PrimaryButton";

interface TransactionDetail {
  id: number;
  paidEmi: number;
  paymentMethod: string;
  status: string;
  transactionDate: string;
  transactionId: string;
}

interface Transaction {
  id: number;
  amount: number;
  esignDocUrl: string;
  orderId: string;
  transactionId: string;
  transactions: TransactionDetail[];
  userId: string;
  paymentMethod?: string;
  transactionDate?: string;
  loanAmount: number;
  emiPaid: number;
}

interface Column {
  id: keyof TransactionDetail;
  label: string;
}

const columns: readonly Column[] = [
  { id: "transactionId", label: "Transaction ID" },
  { id: "transactionDate", label: "Date" },
  { id: "paidEmi", label: "Paid Emi" },
  { id: "status", label: "Status" },
  { id: "paymentMethod", label: "Payment Method" },
];

const TransactionDetails: FC<{ id: string | undefined }> = ({ id }) => {
  const dispatch = useDispatch();
  const [transaction, setTransaction] = useState<Transaction>({
    id: 0,
    amount: 0,
    loanAmount: 0,
    emiPaid: 0,
    esignDocUrl: "",
    orderId: "",
    transactionId: "",
    transactions: [],
    userId: "",
  });
  const [loading, setLoading] = useState<boolean>(false);

  const getAllTransaction = useCallback(async () => {
    try {
      const {
        status,
        data,
      }: {
        status: number;
        data: Transaction;
      } = await axiosInstance.get(URLS.loan(id, "transaction-details"));

      if (status === 200) {
        setTransaction(data);
      } else {
        console.error("Unexpected status:", status);
      }
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          // API - Generate Access Token from Refresh Token
          const { status } = await RefreshToken();
          if (status) {
            await getAllTransaction();
          } else {
            clearStorage("all");
            dispatch(logout());
          }
        }
      }
      console.log(e);
    }
  }, [id, dispatch]);

  useEffect(() => {
    (async () => {
      await getAllTransaction();
    })();
  }, [getAllTransaction]);

  const handleUpdateTransaction = async () => {
    try {
      const { status } = await axiosInstance.post(
        URLS.loan(id, "transaction-details"),
        { ...transaction, status: "Success" },
      );
      if (status === 200) {
        setLoading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "success",
            message: "Transaction recorded successfully",
          }),
        );
        await getAllTransaction();
      }
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          // API - Generate Access Token from Refresh Token
          const { status } = await RefreshToken();
          if (status) {
            await handleUpdateTransaction();
          } else {
            clearStorage("all");
            dispatch(logout());
          }
        }
      }
      console.log(e);
    }
  };

  const handleChange = (value: string, field: string) => {
    setTransaction((prevState) => ({ ...prevState, [field]: value }));
  };

  console.log(transaction);
  console.log(transaction.loanAmount - transaction.emiPaid);

  return (
    <Grid
      container
      style={{
        backgroundColor: "#fdf5f4",
        padding: "40px",
        borderTopLeftRadius: "10px",
        borderBottomRightRadius: "10px",
        width: "100%",
        marginLeft: 0,
      }}
      spacing={2}
    >
      {TransactionDetailsForm(transaction.loanAmount - transaction.emiPaid)
        .map((item: FormModel) => ({
          ...item,
          value: !!transaction
            ? (transaction[item.field as keyof Transaction] as string)
            : "",
        }))
        .map((item: FormModel, index: number) => (
          <Grid
            key={index}
            item
            xs={12}
            lg={6}
            marginBottom={3}
            display={"flex"}
            flexDirection={"row"}
          >
            <Typography
              fontSize={"11px"}
              sx={{
                ...styles.detail,
                width: "200px",
                textDecorationColor: "red",
              }}
            >
              {item.label}
            </Typography>
            <RenderFormFields
              value={item.value as string}
              item={item}
              handleChange={handleChange}
            />
          </Grid>
        ))}
      {/*<Grid item xs={12} lg={6}>*/}
      {/*  <Grid*/}
      {/*    item*/}
      {/*    xs={9}*/}
      {/*    style={{*/}
      {/*      display: "flex",*/}
      {/*      justifyContent: "initial",*/}
      {/*      marginBottom: "40px",*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Typography*/}
      {/*      variant="h6"*/}
      {/*      fontSize={"11px"}*/}
      {/*      sx={{*/}
      {/*        ...styles.detail,*/}
      {/*        width: "200px",*/}
      {/*        textDecorationColor: "red",*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      ESign Document URl:*/}
      {/*    </Typography>*/}
      {/*    <Typography sx={styles.viewButton}>*/}
      {/*      View <KeyboardArrowRightIcon sx={{ fontSize: "16px" }} />*/}
      {/*    </Typography>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
      <Grid item xs={12} textAlign={"end"} marginBottom={3}>
        <PrimaryButton
          disabled={loading}
          type="submit"
          color={"secondary"}
          sx={{
            mb: 1,
            borderRadius: "24px 0px 24px 0px",
            p: 1,
            paddingX: 5,
          }}
          onClick={handleUpdateTransaction}
          fullWidth={false}
        >
          {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
          Update
        </PrimaryButton>
      </Grid>

      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column.id}
                  sx={{
                    ...(styles.headRow as SxProps<Theme>),
                    borderLeft: index === 0 ? "1px solid black" : "none",
                    borderTopLeftRadius:
                      column.label === "Transaction ID" ? "10px" : undefined,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!transaction &&
              transaction.transactions.map((row) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column, index) => {
                      const value = row[column.id as keyof TransactionDetail];
                      return (
                        <TableCell
                          key={column.id}
                          sx={{
                            ...styles.cell,
                            borderLeft:
                              index === 0 ? "1px solid black" : "none",
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {/*<Grid item xs={5}>*/}
      {/*  <Typography sx={styles.toggleButton}>*/}
      {/*    TopUp:*/}
      {/*    <ToggleOnIcon sx={{ color: "#19b600", fontSize: "24px" }} />*/}
      {/*  </Typography>*/}
      {/*</Grid>*/}
    </Grid>
  );
};

export default TransactionDetails;
