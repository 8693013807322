import { FormModel } from "../../../../components/Form";
import { Validators } from "../../../../utils/validators";

export const BusinessDetailsForm: FormModel[] = [
  {
    label: "Firm Name",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "firmName",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #000000",
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "PAN Card",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "businessPanCard",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #000000",
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Firm Type",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "firmType",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #000000",
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "GST No:",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "gstNo",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #000000",
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Business Registration Number:",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "businessRegistrationNo",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #000000",
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Registered Business Address:",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "firmAddress",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #000000",
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "",
    value: "",
    size: "small",
    autoFocus: false,
    type: "divider",
    typeValue: "#E64031",
    variant: "dashed",
    placeholder: "",
    field: "",
    validators: [],
    responsive: { xs: 12 },
    required: true,
    sx: {
      color: "red",
    },
  },
  {
    label: "Nature of Business Activity",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "natureOfBusinessActivity",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #000000",
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    hiddenLabel: true,
    rows: 5,
  },
  {
    label: "Purpose of Loan",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "purposeOfLoan",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #000000",
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    hiddenLabel: true,
    rows: 5,
  },
];
