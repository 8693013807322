import React, { FC, useCallback, useEffect, useState } from "react";
import { Button, Grid, Typography, Stack } from "@mui/material";
import { styles } from "./styles";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import profile from "../../../assets/Frame 48.png";
import TransactionDetails from "./TransactionDetails";
import BankDetails from "./BankDetails";
import PersonalDetails from "./PersonalDetails";
import BusinessDetails from "./BusinessDetails";
import axios from "axios";
import { URLS } from "../../../utils/constants/urls";
import { routes } from "../../../utils/constants/routes";
import axiosInstance from "../../../utils/axios";
import AdditionalDetails from "./AdditionalDetails";
import { LoanStatusForm } from "./LoanStatusForm";
import RenderFormFields from "../../../components/Form/helper";
import CircularProgress from "@mui/material/CircularProgress";
import PrimaryButton from "../../../components/PrimaryButton";
import { useDispatch } from "react-redux";
import { logout, setNotificationMessage } from "../../../utils/redux";
import { RefreshToken } from "../../../utils/refresh-token";
import { clearStorage } from "../../../utils/storage";

const tabs: { label: string }[] = [
  { label: "Personal Details" },
  { label: "Business Details" },
  { label: "Additional Details" },
  { label: "Bank Detail" },
  { label: "Transactions" },
];

interface LoanDetail {
  id: number;
  createdAt: number;
  nameAsPerBankAccount: string;
  referenceNo: string;
  loanAmount: number;
  loanStatus: string;
  loanType: "PERSONAL" | "BUSINESS";
  user: {
    id: number;
    userReference: string;
    email: string;
    organizationName: string | null;
    phone: string;
    fullName: string;
  };
}

interface LoanStatus {
  interestRate: number;
  loanAmount: number;
  orderId: string;
  loanStatus: string;
  userId: string;
  tenure: number | string;
  interestType: string;
}

const LoanDetails: FC = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [loanStatus, setLoanStatus] = useState<LoanStatus>({
    interestRate: 0,
    loanAmount: 0,
    orderId: "",
    loanStatus: "",
    userId: "",
    tenure: 0,
    interestType: "",
  });
  const [loanDetails, setLoanDetails] = useState<LoanDetail>();

  const getLoanDetails = useCallback(async () => {
    try {
      const { status, data } = await axiosInstance.get(URLS.loan(id));
      if (status === 200) {
        setLoanDetails(data);
      }
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          // API - Generate Access Token from Refresh Token
          const { status } = await RefreshToken();
          if (status) {
            await getLoanDetails();
          } else {
            clearStorage("all");
            dispatch(logout());
          }
        }
      }
      console.log(e);
    }
  }, [id, dispatch]);

  const getLoanStatus = useCallback(async () => {
    try {
      const { status, data } = await axiosInstance.get(URLS.loan(id, "status"));
      if (status === 200) {
        setLoanStatus({
          ...data,
          tenure: data.tenure || 3,
          loanStatus: data.status.value || "OPEN",
        });
      }
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          // API - Generate Access Token from Refresh Token
          const { status } = await RefreshToken();
          if (status) {
            await getLoanStatus();
          } else {
            clearStorage("all");
            dispatch(logout());
          }
        }
      }
      console.log(e);
    }
  }, [id, dispatch]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getLoanDetails();
      await getLoanStatus();
      setLoading(false);
    })();
  }, [getLoanDetails, getLoanStatus]);

  const updateLoanStatus = async () => {
    try {
      setLoading(true);
      const { status } = await axiosInstance.post(URLS.loan(id, "status"), {
        ...loanStatus,
        tenure: parseInt(loanStatus.tenure as string) || 3,
      });
      if (status === 200) {
        setLoading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "success",
            message: "Loan Status updated successfully",
          }),
        );
      }
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          // API - Generate Access Token from Refresh Token
          const { status } = await RefreshToken();
          if (status) {
            await updateLoanStatus();
          } else {
            clearStorage("all");
            dispatch(logout());
          }
        } else {
          setLoading(false);
          dispatch(
            setNotificationMessage({
              display: true,
              severity: "error",
              message: "Unable to update Loan Status",
            }),
          );
        }
      } else {
        setLoading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "Unable to update Loan Status",
          }),
        );
      }
      console.log(e);
    }
  };

  const handleDeleteLoan = async () => {
    try {
      await axios.delete(URLS.loan(id));
      console.log("Loan Transaction deleted successfully!");
      Navigate(routes.loans);
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          // API - Generate Access Token from Refresh Token
          const { status } = await RefreshToken();
          if (status) {
            await handleDeleteLoan();
          } else {
            clearStorage("all");
            dispatch(logout());
          }
        } else {
          setLoading(false);
          dispatch(
            setNotificationMessage({
              display: true,
              severity: "error",
              message: "Unable to delete Loan",
            }),
          );
        }
      } else {
        setLoading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "Unable to delete Loan",
          }),
        );
      }
      console.log(e);
    }
  };

  const handleChange = (value: string, field: string) => {
    setLoanStatus((prevState) => ({ ...prevState, [field]: value }));
  };

  return (
    <Grid container spacing={2} style={{ padding: "40px" }}>
      <Grid item md={9}>
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "40px" }}
        >
          <img
            src={profile}
            alt="logo"
            style={{ display: "block", marginRight: "45px" }}
          />
          <Box>
            {!!loanDetails && (
              <>
                <Typography variant="h4" sx={styles.heading}>
                  {loanDetails.loanType === "PERSONAL"
                    ? loanDetails.user.fullName
                    : loanDetails.user.organizationName}
                </Typography>
                {loanDetails.loanType === "BUSINESS" && (
                  <Typography variant="h6" sx={styles.subHeading}>
                    {loanDetails.user.fullName}
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        md={3}
        style={{ display: "flex", justifyContent: "end", marginTop: "70px" }}
      >
        {!!loanDetails && (
          <Box>
            <Typography
              variant="h4"
              fontSize={"15px"}
              sx={{ ...styles.detail, textDecorationColor: "white" }}
            >
              Loan ID: {loanDetails.referenceNo}
            </Typography>
          </Box>
        )}
      </Grid>
      {LoanStatusForm.map((item) => ({
        ...item,
        value: !!loanStatus
          ? loanStatus[item.field as keyof LoanStatus] || ""
          : "",
      })).map((item, index) => (
        <Grid item xs={12} md={2} key={index}>
          <Typography
            fontSize={"11px"}
            variant={"body2"}
            sx={{ ...styles.detail, mb: 3 }}
          >
            {item.label}
          </Typography>
          <RenderFormFields
            value={item.value}
            item={item}
            handleChange={handleChange}
          />
        </Grid>
      ))}
      <Grid item xs={12} md={2} alignSelf={"end"}>
        <PrimaryButton
          disabled={loading}
          type="submit"
          color={"secondary"}
          sx={{
            mb: 1,
            borderRadius: "24px 0px 24px 0px",
            p: 1,
          }}
          onClick={updateLoanStatus}
        >
          {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
          Update
        </PrimaryButton>
      </Grid>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{
            mb: "25px",
            backgroundColor: "#FBE2E0",
            borderTopLeftRadius: "30px",
            borderBottomRightRadius: "30px",
          }}
        >
          {!!loanDetails &&
            tabs
              .filter((tab, index) => {
                return (
                  (index !== 1 && loanDetails.loanType === "PERSONAL") ||
                  (index !== 2 && loanDetails.loanType === "BUSINESS")
                );
              })
              .map((tab, index) => (
                <Button
                  key={index}
                  variant="text"
                  onClick={() => {
                    setActiveTab(index);
                  }}
                  style={{
                    color: activeTab === index ? "white" : "red",
                    borderRadius:
                      index === 0
                        ? "30px 0 0 0"
                        : index === 3
                          ? "0 0 30px 0"
                          : "0",
                    fontWeight: "500",
                    padding: "15px 30px",
                    backgroundColor: activeTab === index ? "red" : "#FBE2E0",
                  }}
                >
                  {tab.label}
                </Button>
              ))}
        </Stack>
      </Grid>
      {!!loanDetails && (
        <Grid item xs={12}>
          {activeTab === 0 && (
            <PersonalDetails id={id} user_id={loanDetails.user.id} />
          )}
          {activeTab === 1 && (
            <>
              {loanDetails.loanType === "PERSONAL" ? (
                <AdditionalDetails id={id} />
              ) : (
                <BusinessDetails id={id} />
              )}
            </>
          )}
          {activeTab === 2 && <BankDetails id={id} />}
          {activeTab === 3 && <TransactionDetails id={id} />}
        </Grid>
      )}
      {!!loanDetails && (
        <Grid item xs={12} md={3}>
          <PrimaryButton
            disabled={loading}
            type="submit"
            color={"secondary"}
            sx={{
              mb: 1,
              borderRadius: "24px 0px 24px 0px",
              p: 1,
            }}
            onClick={handleDeleteLoan}
          >
            {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
            Deleted Loan with ID:
            {loanDetails.referenceNo}
          </PrimaryButton>
        </Grid>
      )}
    </Grid>
  );
};

export default LoanDetails;
