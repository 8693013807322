import React, { FC, useCallback, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import { styles } from "./styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, SxProps, Theme } from "@mui/system";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../utils/constants/routes";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";

interface Users {
  id: number;
  roles: string;
  last_name: string;
  email: string;
}

interface Column {
  id: keyof Users | "action";
  label: string;
}

const columns: readonly Column[] = [
  { id: "roles", label: "Roles" },
  { id: "action", label: "Action" },
];

const Roles: FC = () => {
  const Navigate = useNavigate();
  const rowsPerPage = 15;

  const [page, setPage] = useState(0);
  const [allUsers, setAllUsers] = useState<Users[]>([]);
  console.log(allUsers);
  const getAllUsers = useCallback(async () => {
    try {
      const body = {
        filter: [],
        graphql: null,
        page: 1,
        size: 10,
        sort: [],
      };
      const {
        status,
        data,
      }: {
        status: number;
        data: {
          size: number;
          content: Users[];
          number: number;
          sort: { sorted: boolean; empty: boolean; unsorted: boolean };
          first: boolean;
          last: true;
          totalPages: 1;
          totalElements: 7;
          numberOfElements: 7;
        };
      } = await axiosInstance.post(URLS.user, body);
      if (status === 200) {
        const Users: Users[] = [];
        data.content.forEach((user) => {
          Users.push({
            id: user.id,
            last_name: user.last_name,
            roles: user.roles,
            email:user.email,
          });
        });
        console.log(Users);
        setAllUsers(Users);
      }
    } catch (e) {}
  }, []);

  useEffect(() => {
    (async () => {
      await getAllUsers();
    })();
  }, [getAllUsers]);

  const getPageNumbers = () => {
    const pageCount = Math.ceil(allUsers.length / rowsPerPage);
    const pageNumbers = [];

    if (pageCount <= 5) {
      for (let i = 0; i < pageCount; i++) {
        pageNumbers.push(i + 1);
      }
    } else {
      pageNumbers.push(1, 2, "...", page - 1, page, page + 1, "...", pageCount);
    }

    return pageNumbers;
  };

  const handlePrevButtonClick = () => {
    setPage((prevPage) => Math.max(0, prevPage - 1));
  };

  const handleNextButtonClick = () => {
    setPage(() =>
      Math.min(page + 1, Math.ceil(allUsers.length / rowsPerPage) - 1)
    );
  };

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleLoanDetailClick = (id: number) => {
    const ID = id.toString();
    Navigate(routes.loan(ID));
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ ...styles.Loan, marginBottom: "20px" }}>
          Users Management
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={column.id}
                    sx={{
                      ...(styles.headRow as SxProps<Theme>),
                      borderLeft: index === 0 ? "1px solid black" : "none",
                      borderRight: index === 1 ? "1px solid black" : "none",
                      borderTopLeftRadius:
                        column.label === "Roles" ? "10px" : undefined,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {allUsers.map((row) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column, index) => {
                      const value =row[column.id as keyof Users];
                      const columnName = column.id;

                      return (
                        <TableCell
                          key={column.id}
                          sx={{
                            ...styles.cell,
                            borderLeft:
                              index === 0 ? "1px solid black" : "none",
                            borderRight:
                              index === 1 ? "1px solid black" : "none",
                          }}
                        >
                          {value as string}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            margin: "5px",
          }}
        >
          <IconButton
            onClick={handlePrevButtonClick}
            disabled={page === 0}
            sx={styles.ChangeButton}
          >
            Prev
          </IconButton>
          {getPageNumbers().map((pageNumber, index) => (
            <Typography
              key={index}
              onClick={() => {
                if (typeof pageNumber === "number") {
                  setPage(pageNumber - 1);
                }
              }}
              sx={{
                ...styles.pagination,
                color:
                  typeof pageNumber === "number" && pageNumber === page + 1
                    ? "white"
                    : "black",
                backgroundColor:
                  typeof pageNumber === "number" && pageNumber === page + 1
                    ? "Red"
                    : "white",
                border:
                  typeof pageNumber === "number" && pageNumber === page + 1
                    ? "1px solid red"
                    : "1px solid gray",
              }}
            >
              {pageNumber}
            </Typography>
          ))}
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page === Math.ceil(allUsers.length / rowsPerPage) - 1}
            sx={styles.ChangeButton}
          >
            Next
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Roles;
