import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {StyledEngineProvider, createTheme, ThemeProvider} from "@mui/material/styles";
import {CssBaseline} from "@mui/material";
import {Provider} from "react-redux";
import store from "./utils/redux";
import {BrowserRouter as Router} from "react-router-dom";

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
    palette: {
        mode: "light",
        secondary: {
            main: "#E64031",
            contrastText: "#fdf6f6",
        },
    },
    typography: {
        fontFamily: ["Poppins"].join(","),
    },
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <StyledEngineProvider injectFirst>
        <CssBaseline/>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <Router basename="/">
                    <App/>
                </Router>
            </ThemeProvider>
        </Provider>
    </StyledEngineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
