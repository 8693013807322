import { FC } from "react";
import { Typography } from "@mui/material";
import { styles } from "./styles";
import { Box, SxProps, Theme } from "@mui/system";

interface IndicatorProps {
  textColor?: string;
  label?: string;
}

const Indicator: FC<IndicatorProps> = ({ textColor = "", label = "" }) => {
  return (
    <>
      <Box
        sx={{
          ...(styles.indicatorCircle as SxProps<Theme>),
          backgroundColor: textColor,
        }}
      />
      <Typography
        sx={{ ...styles.indicator, color: textColor, fontSize: "12px" }}
      >
        {label}
      </Typography>
    </>
  );
};

export default Indicator;
