import { URLS } from "../constants/urls";
import { fetchFromStorage, saveToStorage } from "../storage";
import { identifiers } from "../constants/identifiers";
import axiosInstance from "../axios";

export const RefreshToken = async () => {
  try {
    const refreshToken = fetchFromStorage(
      identifiers.refresh_token as string,
      true,
    );
    if (refreshToken) {
      const { status, data } = await axiosInstance.post(URLS.refreshToken, {
        grant_type: identifiers.refresh_token as string,
        refreshToken,
      });
      if (status === 200) {
        saveToStorage(identifiers.access_token as string, data.token, true);
        saveToStorage(
          identifiers.refresh_token as string,
          data.refreshToken,
          true,
        );
        return { status: true };
      }
    }
    return { status: false };
  } catch (e: any) {
    console.log(e);
    console.log(e.response);
    return { status: false };
  }
};
