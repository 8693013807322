import React, { FC, useCallback, useEffect, useState } from "react";
import { Grid, TableSortLabel, Typography } from "@mui/material";
import { styles } from "./styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, SxProps, Theme } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import Indicator from "./Indicator";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../utils/constants/routes";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import { RefreshToken } from "../../../utils/refresh-token";
import { clearStorage } from "../../../utils/storage";
import { useDispatch } from "react-redux";
import { logout } from "../../../utils/redux";

interface Loans {
  id: number;
  reference_number: string;
  borrower_name: string;
  email?: string;
  loan_type: string;
  loan_status: string;
  organization_name: string | null;
  mobile_number: string;
  date_of_application: Date;
  city: string;
  pin_code: string;
}

interface LoansApi {
  id: number;
  createdAt: number;
  nameAsPerBankAccount: string;
  referenceNo: string;
  loanStatus: string;
  loanType: string;
  user: {
    id: number;
    userReference: string;
    email: string | null;
    organizationName: string | null;
    phone: string;
    fullName: string;
    city: string;
    pinCode: string;
    state: string;
  };
}

interface Column {
  id: keyof Loans | "action";
  label: string;
  sort: "asc" | "desc" | false;
  sort_column: string;
}

const ColorMapping: { [key: string]: string[] } = {
  OPEN: ["#2D2DFE", "#2D2DFE40"],
  SANCTIONED: ["#FFA500", "#FFA50040"],
  DISBURSED: ["#00BC00", "#00BC0040"],
  CLOSED: ["#B500B5", "#B500B540"],
  REJECTED: ["#FF1414", "#FF141440"],
};

const Dashboard: FC = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const rowsPerPage: number = 10;

  const [page, setPage] = useState<number>(1);
  const [columns, setColumns] = useState<Column[]>([
    {
      id: "reference_number",
      label: "Loan ID",
      sort: false,
      sort_column: "referenceNo",
    },
    {
      id: "borrower_name",
      label: "Name of Borrower",
      sort: false,
      sort_column: "nameAsPerBankAccount",
    },
    {
      id: "loan_type",
      label: "Loan Type",
      sort: false,
      sort_column: "loanType",
    },
    {
      id: "mobile_number",
      label: "Mobile Number",
      sort: false,
      sort_column: "user.phone",
    },
    {
      id: "city",
      label: "City",
      sort: false,
      sort_column: "user.city",
    },
    {
      id: "pin_code",
      label: "Pincode",
      sort: false,
      sort_column: "user.pincode",
    },
    {
      id: "date_of_application",
      label: "Date of Application",
      sort: "asc",
      sort_column: "createdAt",
    },
    {
      id: "loan_status",
      label: "Status",
      sort: false,
      sort_column: "loanStatus",
    },
    { id: "action", label: "Action", sort: false, sort_column: "" },
  ]);
  const [allLoans, setAllLoans] = useState<{
    totalElements: number;
    loans: Loans[];
    totalPages: number;
  }>({ totalPages: 0, totalElements: 0, loans: [] });

  const getAllLoans = useCallback(async () => {
    try {
      const sorted_column: Column = columns.find(
        (column) => column.sort !== false,
      ) as Column;
      console.log(sorted_column);
      const body = {
        filter: [],
        graphql: null,
        page: page,
        size: rowsPerPage,
        sort: [`${sorted_column.sort_column}:${sorted_column.sort}`],
      };
      const {
        status,
        data,
      }: {
        status: number;
        data: {
          size: number;
          content: LoansApi[];
          number: number;
          sort: { sorted: boolean; empty: boolean; unsorted: boolean };
          first: boolean;
          last: true;
          totalPages: number;
          totalElements: number;
          numberOfElements: number;
        };
      } = await axiosInstance.post(URLS.loans, body);
      if (status === 200) {
        const loans: Loans[] = [];
        data.content.forEach((loan) => {
          loans.push({
            id: loan.id,
            borrower_name: loan.user.fullName,
            reference_number: loan.referenceNo,
            loan_type: loan.loanType,
            loan_status: loan.loanStatus || "OPEN",
            date_of_application: new Date(loan.createdAt),
            mobile_number: loan.user.phone,
            organization_name: loan.user.organizationName,
            city: loan.user.city,
            pin_code: loan.user.pinCode,
          });
        });
        setAllLoans({
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          loans,
        });
      }
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          // API - Generate Access Token from Refresh Token
          const { status } = await RefreshToken();
          if (status) {
            await getAllLoans();
          } else {
            clearStorage("all");
            dispatch(logout());
          }
        }
      }
      console.log(e);
    }
  }, [dispatch, page, columns]);

  useEffect(() => {
    (async () => {
      await getAllLoans();
    })();
  }, [getAllLoans]);

  const getPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 0; i < allLoans.totalPages; i++) {
      pageNumbers.push(i + 1);
    }

    return pageNumbers;
  };

  const handlePrevButtonClick = () => {
    setPage((prevPage) => Math.max(0, prevPage - 1));
  };

  const handleNextButtonClick = () => {
    setPage(() => Math.min(page + 1, allLoans.totalPages));
  };

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleLoanDetailClick = (id: number) => {
    const ID = id.toString();
    Navigate(routes.loan(ID));
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={styles.Loan}>
          All Loans
        </Typography>
        <Box sx={{ textAlign: "end", marginBottom: "10px" }}>
          {Object.keys(ColorMapping).map((status: string, index: number) => (
            <Indicator
              key={index}
              textColor={ColorMapping[status][0]}
              label={status}
            />
          ))}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <>
                    {column.id !== "action" ? (
                      <TableCell
                        key={column.id}
                        sx={{
                          ...(styles.headRow as SxProps<Theme>),
                          borderLeft: index === 0 ? "1px solid black" : "none",
                          borderTopLeftRadius:
                            column.label === "User ID" ? "10px" : undefined,
                        }}
                        onClick={() => {}}
                        sortDirection={column.sort}
                      >
                        <TableSortLabel
                          active={!!column.sort}
                          direction={
                            !!column.sort
                              ? (column.sort as "asc" | "desc")
                              : "asc"
                          }
                          onClick={() => {
                            setColumns(
                              columns.map((column_value) => {
                                if (column_value.id === column.id) {
                                  if (!column_value.sort) {
                                    return { ...column_value, sort: "asc" };
                                  } else {
                                    if (column_value.sort === "asc") {
                                      return { ...column_value, sort: "desc" };
                                    } else {
                                      return { ...column_value, sort: "asc" };
                                    }
                                  }
                                }
                                return { ...column_value, sort: false };
                              }),
                            );
                          }}
                        >
                          {column.label}
                          {!!column.sort ? (
                            <Box component="span" sx={visuallyHidden}>
                              {column.sort === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ) : (
                      <TableCell
                        key={column.id}
                        sx={{
                          ...(styles.headRow as SxProps<Theme>),
                          borderLeft: "none",
                          borderTopLeftRadius: undefined,
                        }}
                      >
                        {column.label}
                      </TableCell>
                    )}
                  </>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {allLoans.loans.map((row) => {
                const textColor = ColorMapping[row.loan_status][0];
                const backgroundColor = ColorMapping[row.loan_status][1];

                return (
                  <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column, index) => {
                      const value =
                        column.id === "borrower_name"
                          ? row.loan_type === "PERSONAL"
                            ? row[column.id]
                            : row.organization_name
                          : row[column.id as keyof Loans];
                      const columnName = column.id;

                      return (
                        <TableCell
                          key={column.id}
                          sx={{
                            ...styles.cell,
                            borderLeft:
                              index === 0 ? "1px solid black" : "none",
                            padding:
                              columnName === "loan_status" ? "6px" : "0px",
                          }}
                        >
                          {columnName === "loan_status" ? (
                            <Typography
                              sx={{
                                ...styles.status,
                                color: textColor,
                                backgroundColor: backgroundColor,
                              }}
                            >
                              {value as string}
                            </Typography>
                          ) : columnName === "borrower_name" ? (
                            <>
                              <Typography
                                sx={{
                                  ...styles.status,
                                  textTransform: "capitalize",
                                }}
                              >
                                {value as string}
                              </Typography>
                              {row.loan_type === "BUSINESS" && (
                                <Typography
                                  sx={{
                                    ...styles.status,
                                    fontWeight: 400,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {row.borrower_name}
                                </Typography>
                              )}
                            </>
                          ) : columnName === "date_of_application" ? (
                            <>{(value as Date).toDateString()}</>
                          ) : columnName === "action" ? (
                            <DriveFileRenameOutlineIcon
                              sx={{
                                color: "red",
                                fontSize: "15px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleLoanDetailClick(row.id)}
                            />
                          ) : (
                            <>{value}</>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            margin: "5px",
          }}
        >
          <IconButton
            onClick={handlePrevButtonClick}
            disabled={page === 1}
            sx={styles.ChangeButton}
          >
            Prev
          </IconButton>
          {getPageNumbers().map((pageNumber, index) => (
            <Typography
              key={index}
              onClick={() => {
                setPage(pageNumber);
              }}
              sx={{
                ...styles.pagination,
                color:
                  typeof pageNumber === "number" && pageNumber === page
                    ? "white"
                    : "black",
                backgroundColor:
                  typeof pageNumber === "number" && pageNumber === page
                    ? "Red"
                    : "white",
                border:
                  typeof pageNumber === "number" && pageNumber === page
                    ? "1px solid red"
                    : "1px solid gray",
              }}
            >
              {pageNumber}
            </Typography>
          ))}
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page === allLoans.totalPages}
            sx={styles.ChangeButton}
          >
            Next
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
