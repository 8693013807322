import store from "./store";
import {
  setAuthentication,
  logout,
  Authenticated,
} from "./reducer/authentication-slice";
import {
  NotificationSeverity,
  setNotificationMessage,
  NotificationDisplay,
  NotificationMessage,
} from "./reducer/notification-slice";

export default store;

export {
  setAuthentication,
  logout,
  Authenticated,
  NotificationSeverity,
  setNotificationMessage,
  NotificationDisplay,
  NotificationMessage,
};
