import React, { Component, ReactElement } from "react";
import { validateInput } from "../../utils/validators/Validators";
import { CalendarPickerView } from "@mui/lab";
import RenderFormFields from "./helper";

export interface FormValidators {
  check: any;
  message: string;
  num?: number;
  gender?: string;
  title?: string;
}

export interface FormModel {
  field: string;
  label: string;
  placeholder: string;
  required: boolean;
  validators: FormValidators[];
  value: string | number;
  disabled?: boolean;
  styleClass?: string;
  responsive?: any;
  autoFocus?: any;
  variant?: any;
  size?: any;
  inputProps?: any;
  startAdornment?: string;
  endAdornment?: string;
  typeValue?: any;
  type?: any;
  options?: {
    label: string;
    value: string;
    icon?: string;
    disabled?: boolean;
  }[];
  style?: any;
  className?: string;
  sx?: any;
  disablePast?: boolean;
  disableFuture?: boolean;
  minDate?: any;
  maxDate?: any;
  openTo?: CalendarPickerView;
  views?: CalendarPickerView[];
  inputFormat?: string;
  hiddenLabel?: boolean;
  rows?: number;
}

export interface FormDataModel {
  [key: string]: string | number | boolean;
}

export interface FormProps {
  isFormUpdated?: boolean;
  values: any;
  model: FormModel[];
  testId?: string;
  onChange?: (
    field: string,
    value: string,
    formData: FormDataModel,
    isFormValid: boolean,
  ) => void;
  card?: any;
  hasError?: boolean;
  fieldError?: string;
}

export default class Form extends Component<FormProps> {
  state: { formData: FormDataModel; isFormValid: boolean } = {
    formData: {},
    isFormValid: false,
  };

  componentDidMount() {
    this.prepareFormData();
  }

  componentDidUpdate(prevProps: Readonly<FormProps>) {
    const { values, isFormUpdated } = this.props;
    if (
      (!!isFormUpdated && isFormUpdated !== prevProps.isFormUpdated) ||
      (values &&
        Object.keys(values).length > 0 &&
        JSON.stringify(values) !== JSON.stringify(prevProps.values))
    ) {
      this.prepareFormData();
    }
  }

  handleChange = (
    value: string,
    field: string,
    error?: { error: boolean; message: string },
    deleted?: { deletedField: string; DeletedFile: string },
  ) => {
    const formData: FormDataModel = this.state.formData;

    formData[field] = value;
    if (deleted?.deletedField === field) {
      formData[field + "deleted"] = deleted?.DeletedFile;
    }
    formData[field + "Error"] = !!(error && error.error);
    this.setState({
      formData,
      isFormValid: this.validateForm(formData),
    });
    if (this.props.onChange) {
      const isFormValid = this.validateForm(formData);
      this.props.onChange(field, value, formData, isFormValid);
    }
  };

  validateForm = (formData: FormDataModel) => {
    const { model } = this.props;
    let isFormValid = true;
    model.forEach((item) => {
      if (item.required || formData[item.field + "Error"]) {
        isFormValid = isFormValid && !formData[item.field + "Error"];
      }
    });
    return isFormValid;
  };

  getFormData = () => {
    const { formData, isFormValid } = this.state;
    return { formData, isFormValid };
  };

  resetForm = () => {
    this.prepareFormData();
  };

  prepareFormData() {
    const { model, values } = this.props;
    const formData: FormDataModel = {};
    if (values && Object.keys(values).length !== 0) {
      model.forEach((item) => {
        formData[item.field] =
          values &&
          (values[item.field] || values[item.field] === 0) &&
          values[item.field] !== ""
            ? values[item.field]
            : "";
        const error = validateInput(item.validators, values[item.field]);
        formData[item.field + "Error"] =
          (item.required && !(!!values && !!values[item.field])) ||
          (!!values[item.field] && !!(error && error.error));
      });
      this.setState({ formData, isFormValid: this.validateForm(formData) });
    } else {
      model.forEach((item) => {
        formData[item.field] =
          values && values[item.field] ? values[item.field] : "";
        formData[item.field + "Error"] = item.required;
      });

      this.setState({ formData, isFormValid: this.validateForm(formData) });
    }
  }

  renderFormFields() {
    const { model, hasError, fieldError } = this.props;
    const { formData } = this.state;
    const arrayOfFields: ReactElement[] = [];
    model.forEach((item, key) => {
      arrayOfFields.push(
        <RenderFormFields
          key={key}
          hasError={hasError}
          fieldError={fieldError}
          value={formData[item.field]}
          item={item}
          handleChange={this.handleChange}
        />,
      );
    });

    return arrayOfFields;
  }

  render() {
    return this.renderFormFields();
  }
}
