import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

export const styles: { [key: string]: SxProps<Theme> } = {
  heading: {
    mb: 2,
    fontWeight: "600",
  },
  subHeading: {
    color: "gray",
    fontSize: "12px",
  },
  detail: {
    display: "flex",
    flexDirection: "row",
    fontWeight: "600",
    textDecoration: "underline",
    alignItems: "center",
  },
  width200: { width: "200px" },
  headRow: {
    backgroundColor: "#FBE2E0",
    textAlign: "center",
    fontSize: "11px",
    fontWeight: "bold",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
  },
  cell: {
    textAlign: "center",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    fontSize: "11px",
    fontWeight: "420",
  },
  buttons: {
    borderTopLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    fontSize: "10px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    padding: "10px 25px",
    color: "white",
    backgroundColor: "#e64031",
    width: "fit-content",
    cursor: "pointer",
  },
  viewButton: {
    borderRadius: "5px",
    fontSize: "10px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    padding: "8px",
    color: "white",
    backgroundColor: "#e64031",
    width: "80px",
  },
  toggleButton: {
    borderTopLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    fontSize: "15px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    padding: "10px 25px",
    color: "#e64031",
    backgroundColor: "white",
    width: "fit-content",
    marginTop: "40px",
  },
  inputBox: {
    fontSize: "11px",
    fontWeight: "600",
    backgroundColor: "white",
    width: "25%",
    borderTopLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    border: "1px solid black",
    padding: "8px",
  },
};
