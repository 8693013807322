import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { CircularProgress, Grid, Pagination, Typography } from "@mui/material";
import axiosInstance from "../../../../utils/axios";
import { URLS } from "../../../../utils/constants/urls";
import { styles } from "../styles";
import { FormModel } from "../../../../components/Form";
import RenderFormFields from "../../../../components/Form/helper";
import { PersonalDetailsForm } from "./PersonalDetailsForm";
import PrimaryButton from "../../../../components/PrimaryButton";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { useDispatch } from "react-redux";
import { RefreshToken } from "../../../../utils/refresh-token";
import { clearStorage } from "../../../../utils/storage";
import { logout } from "../../../../utils/redux";
import Modal from "../../../../components/modal/Modal";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { GOOGLE_API_KEY } from "../../../../utils/constants/constants";

interface PersonalDetail {
  aadhaarNo: string;
  emailId: string;
  mobileNo: string;
  name: string;
  panNo: string;
  latitude: number;
  longitude: number;
}

interface UserSms {
  content: {
    id: number;
    senderName: string;
    senderNumber: string;
    message: string;
    date: number;
    user: {
      id: number;
      userReference: string;
      email: string;
      fullName: string;
    };
  }[];
  size: number;
  number: number;
  page: number;
  totalPages: number;
}

const PersonalDetails: FC<{
  id: string | undefined;
  user_id: number | undefined;
}> = ({ id, user_id }) => {
  const dispatch = useDispatch();
  const [personalDetails, setPersonalDetails] = useState<PersonalDetail>();
  const [loading, setLoading] = useState<boolean>(false);
  const [openLocationModal, setOpenLocationModal] = useState<boolean>(false);
  const [openSmsModal, setOpenSmsModal] = useState<boolean>(false);
  const [userSms, setUserSms] = useState<UserSms>({
    content: [],
    size: 10,
    number: 1,
    page: 1,
    totalPages: 0,
  });

  const getAllDetails = useCallback(async () => {
    try {
      setLoading(true);
      const {
        status,
        data,
      }: {
        status: number;
        data: PersonalDetail;
      } = await axiosInstance.get(URLS.loan(id, "personal-details"));
      setLoading(false);
      if (status === 200) {
        setPersonalDetails(data);
      }
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          // API - Generate Access Token from Refresh Token
          const { status } = await RefreshToken();
          if (status) {
            await getAllDetails();
          } else {
            setLoading(false);
            clearStorage("all");
            dispatch(logout());
          }
        }
      }
      setLoading(false);
      console.log(e);
    }
  }, [id, dispatch]);

  useEffect(() => {
    (async () => {
      await getAllDetails();
    })();
  }, [getAllDetails]);

  const getUserSMS = useCallback(
    async (page?: number) => {
      try {
        const body = {
          filter: [],
          graphql: null,
          page: page || 1,
          size: 10,
          sort: [],
        };
        const { status, data } = await axiosInstance.post(
          URLS.sms(user_id),
          body,
        );
        if (status === 200) {
          if (!openSmsModal) {
            setOpenSmsModal(true);
          }
          setUserSms((prevState) => ({
            ...prevState,
            ...data,
            page: data.number,
          }));
        }
      } catch (e: any) {
        if (e.response) {
          if (e.response.status === 401) {
            // API - Generate Access Token from Refresh Token
            const { status } = await RefreshToken();
            if (status) {
              await getUserSMS();
            } else {
              setLoading(false);
              clearStorage("all");
              dispatch(logout());
            }
          }
          if (e.response.status === 404) {
            if (!openSmsModal) {
              setOpenSmsModal(true);
            }
            setUserSms((prevState) => ({ ...prevState, content: [] }));
          }
        }
        setLoading(false);
        console.log(e);
      }
    },
    [dispatch, openSmsModal, user_id],
  );

  const smsModalContent = useMemo(() => {
    if (userSms.content.length > 0) {
      return (
        <Grid container>
          {userSms.content.map((sms, index) => (
            <Grid
              key={index}
              item
              borderBottom={"1px dashed #dddddd"}
              paddingY={2}
            >
              <Typography variant={"h6"} sx={{ fontWeight: "bold" }}>
                {sms.senderName}
              </Typography>
              <Typography variant={"body2"} sx={{ marginTop: 2 }}>
                {sms.message}
              </Typography>
            </Grid>
          ))}
          {userSms.totalPages > 1 && (
            <Pagination
              count={userSms.totalPages}
              page={userSms.page}
              onChange={async (event, page) => {
                console.log(page);
                setUserSms((prevState) => ({
                  ...prevState,
                  page,
                }));
                await getUserSMS(page);
              }}
            />
          )}
        </Grid>
      );
    } else {
      return <Grid container></Grid>;
    }
  }, [userSms.content, userSms.totalPages, userSms.page, getUserSMS]);

  const smsModal = useMemo(() => {
    return (
      <Modal
        open={openSmsModal}
        setModalClose={setOpenSmsModal}
        children={smsModalContent}
        title={"SMS Logs"}
        size="sm"
        closeButton
        className={undefined}
      />
    );
  }, [openSmsModal, smsModalContent]);

  const locationModalContent = useMemo(() => {
    return (
      <Grid container sx={{ height: "400px" }}>
        <Grid item padding={2} xs={12}>
          {!!personalDetails && (
            <APIProvider apiKey={GOOGLE_API_KEY}>
              <Map
                zoom={18}
                center={{
                  lat: personalDetails.latitude,
                  lng: personalDetails.longitude,
                }}
                gestureHandling={"greedy"}
                disableDefaultUI={true}
              >
                <Marker
                  position={{
                    lat: personalDetails.latitude,
                    lng: personalDetails.longitude,
                  }}
                />
              </Map>
            </APIProvider>
          )}
        </Grid>
      </Grid>
    );
  }, [personalDetails?.latitude, personalDetails?.longitude]);

  const locationModal = useMemo(() => {
    return (
      <Modal
        open={openLocationModal}
        setModalClose={setOpenLocationModal}
        children={locationModalContent}
        title={"Loan Requested at"}
        size="sm"
        closeButton
        className={undefined}
      />
    );
  }, [openLocationModal, locationModalContent]);

  return (
    <Grid
      container
      style={{
        backgroundColor: "#fdf5f4",
        padding: "40px",
        borderTopLeftRadius: "10px",
        borderBottomRightRadius: "10px",
        width: "100%",
        marginLeft: 0,
      }}
      spacing={2}
    >
      {smsModal}
      {locationModal}
      {PersonalDetailsForm.map((item: FormModel) => ({
        ...item,
        value: !!personalDetails
          ? personalDetails[item.field as keyof PersonalDetail] || ""
          : "",
      })).map((item: FormModel, index: number) => (
        <Grid
          key={index}
          item
          xs={12}
          lg={6}
          marginBottom={3}
          display={"flex"}
          flexDirection={"row"}
        >
          <Typography
            fontSize={"11px"}
            sx={{
              ...styles.detail,
              width: "200px",
              textDecorationColor: "red",
            }}
          >
            {item.label}
          </Typography>
          <RenderFormFields
            value={item.value as string}
            item={item}
            handleChange={() => {}}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        {/*<Grid item xs={3} style={{ textAlign: "end", marginBottom: "20px" }}>*/}
        {/*  <PrimaryButton*/}
        {/*    disabled={loading}*/}
        {/*    type="submit"*/}
        {/*    color={"secondary"}*/}
        {/*    sx={styles.buttons}*/}
        {/*    onClick={() => getUserSMS()}*/}
        {/*  >*/}
        {/*    {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}*/}
        {/*    SMS Logs*/}
        {/*  </PrimaryButton>*/}
        {/*</Grid>*/}
        <Grid
          item
          xs={9}
          style={{
            display: "flex",
            justifyContent: "initial",
            marginBottom: "40px",
          }}
        >
          <Typography
            variant="h6"
            fontSize={"11px"}
            sx={{
              ...styles.detail,
              width: "200px",
              textDecorationColor: "red",
              paddingRight: "10px",
            }}
          >
            Loan Requested At:
          </Typography>
          <PrimaryButton
            disabled={loading}
            type="submit"
            color={"secondary"}
            sx={{
              fontSize: "10px",
              fontWeight: "600",
              display: "flex",
              justifyContent: "center",
              width: "100px",
              mb: 1,
              borderRadius: "24px 0px 24px 0px",
              p: 1,
            }}
            onClick={() => {
              if (
                !!personalDetails &&
                !!personalDetails.latitude &&
                !!personalDetails.longitude
              ) {
                setOpenLocationModal(true);
              }
            }}
          >
            {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
            <FmdGoodIcon /> Click here
          </PrimaryButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PersonalDetails;
