import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { MainWrapper } from "./utils/theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Routes from "./routes";

import "./App.scss";
import { fetchFromStorage } from "./utils/storage";
import { identifiers } from "./utils/constants/identifiers";
import { setAuthentication } from "./utils/redux";

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const access_token = fetchFromStorage(
      identifiers.access_token as string,
      true,
    );
    const refresh_token = fetchFromStorage(
      identifiers.refresh_token as string,
      true,
    );
    if (access_token && refresh_token) {
      dispatch(
        setAuthentication({
          token: access_token,
          refreshToken: refresh_token,
        }),
      );
    }
    setLoading(false);
  }, [dispatch]);

  return (
    <MainWrapper>
      {!loading && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container className="main-body">
            <Routes />
          </Grid>
        </LocalizationProvider>
      )}
    </MainWrapper>
  );
}

export default App;
