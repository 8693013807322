import {
  configureStore,
  EnhancedStore,
  StoreEnhancer,
  Tuple,
  UnknownAction,
} from "@reduxjs/toolkit";
import authenticationSlice from "../reducer/authentication-slice";
import notificationSlice from "../reducer/notification-slice";

const store: EnhancedStore<
  any,
  UnknownAction,
  Tuple<[StoreEnhancer<any>, StoreEnhancer]>
> = configureStore({
  reducer: {
    authenticationSlice,
    notificationSlice,
  },
});

export default store;
