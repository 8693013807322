import React, {FC} from "react";
import {List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar} from "@mui/material";
import {images} from "../../utils/constants/images";
import {menu} from "../../utils/constants/constants";
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import {useLocation, useNavigate} from "react-router-dom";

const Drawer: FC = () => {
    const Navigate = useNavigate();
    const location = useLocation();

    const icon = (icon_name: string) => {
        switch (icon_name) {
            case "money":
                return (<CurrencyRupeeOutlinedIcon/>);
            case "users":
                return (<GroupOutlinedIcon/>);
            case "roles":
                return (<EngineeringOutlinedIcon/>);
        }
    }

    return (
        <>
            <Toolbar sx={{backgroundColor: "#FBE2E0"}}>
                <img src={images.logo} alt={'logo'}/>
            </Toolbar>
            <List sx={{p: 1, pl: 0}}>
                {menu.map((menu_item, index) => (
                    <ListItem key={index} disablePadding sx={{
                        border: "1px solid #ffffff",
                        borderTopRightRadius: "50px",
                        borderBottomRightRadius: "50px",
                        borderLeft: "none",
                        backgroundColor: location.pathname === menu_item.link ? "#E64031" : "#FBE2E0"
                    }}>
                        <ListItemButton onClick={() => Navigate(menu_item.link)}>
                            <ListItemIcon>
                                {icon(menu_item.icon)}
                            </ListItemIcon>
                            <ListItemText primary={menu_item.title}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </>
    )
}

export default Drawer;
