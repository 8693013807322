import { createSlice } from "@reduxjs/toolkit";
import { clearStorage, fetchFromStorage, saveToStorage } from "../../storage";
import { identifiers } from "../../constants/identifiers";

interface AuthenticationState {
  authenticated: boolean;
}

const access_token = fetchFromStorage(identifiers.access_token as string);

const authenticationSlice = createSlice({
  name: "authenticationSlice",
  initialState: {
    authenticated: !!access_token || false,
  } as AuthenticationState,
  reducers: {
    setAuthentication(
      state,
      action: {
        payload: {
          [key: string]: string | boolean | { [key: string]: string };
        };
      },
    ) {
      if (action.payload) {
        saveToStorage(
          identifiers.access_token as string,
          action.payload.token as string,
          true,
        );
        saveToStorage(
          identifiers.refresh_token as string,
          action.payload.refreshToken as string,
          true,
        );
        state.authenticated = true;
      } else {
        clearStorage("all");
        state.authenticated = false;
      }
    },
    logout(state: AuthenticationState) {
      clearStorage("all");
      state.authenticated = false;
    },
  },
});

export default authenticationSlice.reducer;

export const { setAuthentication, logout } = authenticationSlice.actions;

export const Authenticated = (state: {
  authenticationSlice: AuthenticationState;
}) => state.authenticationSlice.authenticated;
