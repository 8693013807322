import { FormModel } from "../../../components/Form";
import { Validators } from "../../../utils/validators";
import {
  interest_type,
  loan_status,
  loan_tenure,
} from "../../../utils/constants/constants";

export const LoanStatusForm: FormModel[] = [
  {
    label: "Approved Loan Amount",
    value: "",
    size: "small",
    autoFocus: true,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "loanAmount",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
      { check: Validators.amount, message: "Please enter a valid amount" },
      {
        check: Validators.max,
        num: 100000,
        message: "Approved Loan Amount cannot be greater than 100000",
      },
    ],
    responsive: { xs: 12 },
    required: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #000000",
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Tenure",
    value: "",
    size: "small",
    autoFocus: false,
    type: "drop-down",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "tenure",
    options: loan_tenure,
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: false,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      border: "1px solid #000000",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
        paddingRight: "40px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Interest Rate",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "interestRate",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
      {
        check: Validators.interest,
        message: "Please enter a valid rate of interest",
      },
    ],
    endAdornment: "% per Month",
    responsive: { xs: 12 },
    required: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #000000",
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Loan Status",
    value: "",
    size: "small",
    autoFocus: false,
    type: "drop-down",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "loanStatus",
    options: loan_status,
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: false,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      border: "1px solid #000000",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
        paddingRight: "40px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Interest Type",
    value: "",
    size: "small",
    autoFocus: false,
    type: "drop-down",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "interestType",
    options: interest_type,
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: false,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      border: "1px solid #000000",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
        paddingRight: "40px",
      },
    },
    hiddenLabel: true,
  },
];
