import React, { FC, useCallback, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import axiosInstance from "../../../../utils/axios";
import { URLS } from "../../../../utils/constants/urls";
import { FormModel } from "../../../../components/Form";
import { styles } from "../styles";
import RenderFormFields from "../../../../components/Form/helper";
import { BankDetailsForm } from "./BankDetailsForm";
import { useDispatch } from "react-redux";
import { RefreshToken } from "../../../../utils/refresh-token";
import { clearStorage } from "../../../../utils/storage";
import { logout } from "../../../../utils/redux";

interface BankDetail {
  id?: number;
  accountNo?: string;
  amount?: number;
  bankName?: string;
  branch?: string;
  ifscCode?: string;
  payeeName?: string;
  registrationNo?: string;
}

const BankDetails: FC<{ id: string | undefined }> = ({ id }) => {
  const dispatch = useDispatch();
  const [beneficiaryDetails, setBeneficiaryDetails] = useState<BankDetail>({});

  const getAllDetails = useCallback(async () => {
    try {
      const {
        status,
        data,
      }: {
        status: number;
        data: BankDetail;
      } = await axiosInstance.get(URLS.loan(id, "account-details"));

      if (status === 200) {
        setBeneficiaryDetails(data);
      } else {
        console.error("Unexpected status:", status);
      }
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          // API - Generate Access Token from Refresh Token
          const { status } = await RefreshToken();
          if (status) {
            await getAllDetails();
          } else {
            clearStorage("all");
            dispatch(logout());
          }
        }
      }
      console.log(e);
    }
  }, [id, dispatch]);

  useEffect(() => {
    (async () => {
      await getAllDetails();
    })();
  }, [getAllDetails]);

  return (
    <Grid
      container
      style={{
        backgroundColor: "#fdf5f4",
        padding: "40px",
        borderTopLeftRadius: "10px",
        borderBottomRightRadius: "10px",
        width: "100%",
        marginLeft: 0,
      }}
      spacing={2}
    >
      {BankDetailsForm.map((item: FormModel) => ({
        ...item,
        value: beneficiaryDetails[item.field as keyof BankDetail] || "",
      })).map((item: FormModel, index: number) => (
        <Grid
          key={index}
          item
          xs={12}
          lg={6}
          marginBottom={3}
          display={"flex"}
          flexDirection={"row"}
        >
          <Typography
            fontSize={"11px"}
            sx={{
              ...styles.detail,
              width: "200px",
              textDecorationColor: "red",
            }}
          >
            {item.label}
          </Typography>
          <RenderFormFields
            value={item.value as string}
            item={item}
            handleChange={() => {}}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default BankDetails;
