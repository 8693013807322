import React, { FC } from "react";
import { validateInput } from "../../utils/validators/Validators";
import { Dayjs } from "dayjs";
import { identifiers } from "../../utils/constants/identifiers";
import { FormValidators } from "../Form";
import { DesktopDatePicker, DesktopDatePickerProps } from "@mui/x-date-pickers";

interface OwnProps {
  field?: string;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  typeValue?: string;
  textChange: (
    value: string,
    field: string,
    error?: { error: boolean; message: string },
  ) => void;
  validators?: FormValidators[];
  hasError?: boolean;
  fieldError?: string | boolean;
  label?: string;
}

const DateInput: FC<OwnProps & DesktopDatePickerProps<any>> = ({
  hasError,
  validators,
  textChange,
  field,
  value,
  placeholder,
  fieldError,
  disabled,
  label,
  typeValue,
  format,
  ...rest
}) => {
  const handleChange = (value: Dayjs | null) => {
    if (value) {
      const inputError = validateInput(
        validators as FormValidators[],
        value.format(identifiers.date_input as string),
      );
      textChange(
        value.format(identifiers.date_input as string),
        field as string,
        inputError,
      );
    }
  };

  return (
    <DesktopDatePicker
      {...rest}
      label={label}
      format={format || (identifiers.date_format as string)}
      value={value}
      onChange={(newValue: Dayjs | null) => handleChange(newValue)}
      disabled={disabled}
      className={`input-field ${fieldError}`}
    />
  );
};

export default DateInput;
