import { FormModel } from "../../../../components/Form";
import { Validators } from "../../../../utils/validators";

export const PersonalDetailsForm: FormModel[] = [
  {
    label: "Name",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "name",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #000000",
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Mobile No.",
    value: "",
    size: "small",
    autoFocus: true,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "mobileNo",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #000000",
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
        paddingRight: "40px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Email ID",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "emailId",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #000000",
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
        paddingRight: "40px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "PAN Card",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "panNo",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
      { check: Validators.amount, message: "Please enter a valid Amount" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #000000",
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
        paddingRight: "40px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Aadhaar No.",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "aadhaarNo",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
      { check: Validators.amount, message: "Please enter a valid Amount" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #000000",
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
        paddingRight: "40px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Address",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "otherAddress",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #000000",
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
        paddingRight: "40px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "City",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "city",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #000000",
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
        paddingRight: "40px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "State",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "state",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #000000",
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
        paddingRight: "40px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Pin Code",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "pinCode",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    disabled: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #000000",
        borderTopLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
        paddingRight: "40px",
      },
    },
    hiddenLabel: true,
  },
];
