export const URLS: { [key: string]: any } = {
  login: "/api/validate-password",
  loans: "/admin/loan-details/page",
  loan: (id: string, tab?: string) =>
    `/admin/loan-details/${id}${!!tab ? "/" + tab : ""}`,
  refreshToken: "/api/refresh-token",
  // transactionDetails: (id: string) =>
  //   `/admin/loan-details/${id}/transaction-details`,
  // beneficialDetails: (id: string) =>
  //   `/admin/loan-details/${id}/beneficiary-details`,
  // updateStatus: (id: string) => `/admin/loan-details/${id}/status`,
  // personalDetails: (id: string) => `/admin/loan-details/${id}/personal-details`,
  // bankDetails: (id: string) => `/admin/loan-details/${id}/account-details`,
  // organizationDetails: (id: string) =>
  //   `/admin/loan-details/${id}/organization-details`,
  user: "/admin/users/page",
  sms: (id: number) => `/admin/users/${id}/sms/page`,
};
